import React, { useEffect, useState } from 'react';
import { ImagePreview,Popup } from 'react-vant';
import './cards.css';
import * as service from '../../service';
import DropdownPopup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import Report from '../Report';

import ReportSelect from '../Report/select';
import Unlock from '../Unlock';
import moment from 'moment';

const Dropdown =(props)=>{
    const navigate = useNavigate();
    return (
        <DropdownPopup trigger={open => (    
          <img className='drop-img' src='../../images/drop-icon.png'/>
                )}
            position="bottom right"
            closeOnDocumentClick >
                <div className="leave-menu">
                    <div className="item" onClick={()=>{
                        props.onReport&&props.onReport();
                    }}>举报/投诉</div>
                    
                </div>
         
        </DropdownPopup>
    )
 } 

const CardItem=(props)=>{
    const navigate = useNavigate();
    const isfree = props.item?.isfree;
    const righticon = props.item?.righticon;
    // let {isfree,righticon}=props.item;
    const [member,setMember] = useState(props.member);
    const [showReport,setShowReport] = useState(false);
    const [showReportSelect,setShowReportSelect] = useState(false);
    const [reportType,setReportType] =useState('');
    const [showPurchase,setShowPurchase] = useState(false);
    const [price,setPrice] = useState(0);
    // console.warn('[debug]member_id:',props.item?.member_id,member);
    useEffect(()=>{
        // if(!member&&props.item?.member_id){
        if(!member&&props.item?.member_id)service.getProfile(props.item?.member_id).then(ret=>{
            setMember(ret.data);
        })
        if(props.member){
            setMember(props.member);
        }
        // }
    },[props.item?.member_id,props.member]);
    return (<div className='card-item' onClick = {()=>{
        navigate('/detail/'+props.item?.id);
    }}>
           
            <div className='card-item-top fx-box fx-jc-sb'>
                <div className='fx-box fx-ai-s'>
                    <img src={member?.avatar}/>
                    <div className='font-size13'>
                       <div className='font-color3'>{member?.nickname}</div>
                       <div className='font-color4 card-item-top-time'>{moment(props.item?.created_at).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                </div>
                {
                    righticon==='arrow'?(<img className='card-item-top-arrow-img' src='../../images/drop-down.png'/>):
                    
                    (
                        <Dropdown onReport={()=>{
                            console.log('...........',props.item);
                            setShowReportSelect(true);
                        }}/>
                    // <img className='drop-img' src='../../images/drop-icon.png'/>
                    )
                }
                
            </div>

            <div className='card-item-section font-size13 font-color4'>
               {props?.item?.desc}
            </div>

            {
                (!props.item?.need_purchase||props.item?.is_reward)?
                <div className='card-item-album'>
                {/* <img src='../../images/mock-circle-icon.png'/>
                <img src='../../images/mock-circle-icon.png'/>
                <img src='../../images/mock-circle-icon.png'/>
                <img src='../../images/mock-circle-icon.png'/>
                <img src='../../images/mock-circle-icon.png'/> */}
                {props.item?.photo?.map((p,idx)=>{
                    return <img src={p} key={'cpk_'+idx} onClick={(e)=>{
                        e.stopPropagation();
                        ImagePreview.open({images:props.item?.photo,startPosition:idx})
                    }}/>;
                })}
                {
                    props.item?.video?(<video controls poster={props.item?.video?.replace('http:','https:')} className='circle-img'>
                    <source  src={props.item?.video?.replace('http://','https://')}
                        type="video/mp4"/>
                </video>):null
                }
            </div>:null
            }
            

            {/**动态判断 内容免费还是付费**/}
            {
                props.item?.need_purchase?(<div className='card-item-nofree fx-box fx-jc-sb fx-ai-c' onClick={()=>{
                    setPrice(props.item?.price);
                    setShowPurchase(true);
                }}>
                        <div className='fx-box fx-ai-c'>
                        <img src='../../images/pay-icon.png'/>
                        <span className='font-color1'>{props.item?.price}金币</span>
                        </div>
                        <div className='fx-box fx-ai-c'>
                        <div className='font-color1'>
                            <div>圈内vip可免费查看</div>
                            <div>30张图片</div>
                        </div>
                        <img src='../../images/right-icon.png'/>
                        </div>
                </div>):<div></div>
            }
            

            <div className='card-commands fx-box fx-ai-c font-color6'>
                <div className='fx-box fx-ai-c'>
                   <img src='../../images/like-icon.png'/>
                   <span>{props?.item?.like_count}</span>
                </div>
                
                <div className='fx-box fx-ai-c'>
                   <img src='../../images/msg-icon.png'/>
                   <span>{props?.item?.comment_count}</span>
                </div>
            </div>
            <ReportSelect visible={showReportSelect}
            onSelect={(type)=>{
                setReportType(type);
                setShowReportSelect(false);
                setShowReport(true);
            }}
             onClose={()=>{
                setShowReportSelect(false);
            }}/>
            <Report title={reportType} visible={showReport} 
                onClose = {()=>{
                setShowReport(false);
            }}
                onReturn = {
                    ()=>{
                        setShowReport(false);
                        setShowReportSelect(true);
                    }
                }
                onSubmit={
                    (desc)=>{
                        service.report(props.item?.id,reportType,desc).then(ret=>{
                            console.log('[DEBUG]report return:',ret);
                        }).finally(()=>{
                            setShowReport(false);
                        });
                        
                    }
                }
            />
            <Unlock visible={showPurchase} price={price} onClose={()=>{
                setShowPurchase(false);
            }}/>
        
    </div>);
}

export default CardItem;