import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Toast } from "react-vant";
import CardItem from "../../components/Cards/CardItem";
import { Suggestion } from "../../components/Suggestion";
import * as service from '../../service';
import "./moment.css";

const MomentRAW = (props)=>{
    const params = useParams();
    const navigate = useNavigate();

    const [circle,setCircle]  = useState(null);
    const [feed,setFeed] = useState(null);

    useEffect(()=>{
        service.loadFeed(params.id).then((ret)=>{
            console.log('[DEBUG]load post ret:',ret);
            setFeed(ret.data);
            
            return service.loadCircle(ret.data?.circle_id)
        }).then(ret=>{
            if(ret.code==0){
                setCircle(ret.data);
            }
        }).catch(err=>{
            props.dispatch({type:'SHOW_LOGIN_DIALOG'});
        });
    },[params.id,props.profile]);
    return (
        <div className="moment fx-box">
            <div className="fx-box fx-fd-c moment-main">
                <div className="main">
                    {feed?<CardItem item = {feed}/>:null}
                </div>                
                {circle?<div className="fx-box fx-fd-c circle-container">
                    <div className="caption">所属圈子</div>
                    <div className="circle">
                        <div><img src={circle?.avatar}></img></div>
                        <div className="fx-1">
                            <div className="title">{circle?.title}</div>
                            <div className="sub">{circle?.total_feeds}帖子</div>
                        </div>
                        <div>
                            {
                                !circle?.is_joined?(circle?.price?
                                <div className="m-join-btn" onClick={()=>{
                                    console.log(circle);
                                    service.joinCircle(circle.id).then(ret=>{
                                        console.log(ret);
                                        if(ret.code!=0){
                                            Toast.fail(ret.msg);
                                        }
                                        else {
                                            navigate('/circle/'+circle.id);
                                        }
                                    }).catch(err=>{
                                        console.error('[DEBUG]APP ERROR:',err)
                                        props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                                    });
                                }}>付费加入</div>:
                                <div className="m-join-btn"  onClick={()=>{
                                    console.log(circle);
                                    service.joinCircle(circle.id).then(ret=>{
                                        if(ret.code==0){
                                            navigate('/circle/'+circle.id);
                                        }
                                    }).catch(e=>{
                                        props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                                    }).catch(err=>{
                                        console.error('[DEBUG]APP ERROR:',err)
                                        props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                                    });
                                }}>免费加入</div>):
                                (
                                    <div className="m-join-btn"  onClick={()=>{
                                        console.log(circle);
                                        navigate('/circle/'+circle.id)
                                    }}>查看圈子</div>
                                )
                            }
                            
                        </div>
                    </div>
                </div>:null}
            </div>
            <Suggestion/>

        </div>
    )
}

export const Moment = connect(state=>{
    return {
        loginInfo:state.loginInfo,
        profile:state.profile
    }
},dispatch=>{
    return {dispatch}
})(MomentRAW);