// import { Box } from './Box';
import './home.css';

// import { STATICROOMS,offRooms, DefaultPosterUrl } from '../../mock';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import * as service from '../../service';
import { Suggestion } from '../../components/Suggestion';
import { Loading } from 'react-vant';



export const Home= (props)=>{
    const navigate= useNavigate();
    const [feeds ,setFeeds]=useState([]);
    
    const [circlePage,setCirclePage] = useState(0);
    const [circlePageCount,setCirclePageCount] = useState(0);
    const [loading,setLoading] = useState(true);
    const [nextPage,setNextPage] = useState(0);

    useEffect(()=>{
        if(feeds.length==0){
            service.loadFeeds().then(ret=>{
                console.warn('[DEBUG]feeds:',ret.data.data);
                setFeeds(ret.data.data.data);
                setNextPage(ret.data.data.next_page);
            }).finally(()=>{
                setLoading(false);
            })
        }
    },[])
    return (
        <div className='fx-box fx-jc-c'>
                <div className="home-page fx-box fx-fd-c">
                            <div className="fx-box fx-jc-c">
                                <div className="fx-box fx-fd-c section home-main" >
                                    {/* <div className='fx-box section-header fx-ai-c'>
                                        <div>
                                            <img src="/static/images/icon1.png" className='header-icon'/>
                                        </div>
                                        <div className='title'>圈子列表</div>
                                    </div> */}
                                    <div className="fx-box fx-wrap fx-fd-c">


                                        {/* <div className='post' onClick={()=>{
                                            navigate('/circle/'+1)
                                        }}>
                                            <div className='fx-box fx-fd-c fx-1'>
                                                <div className='color-1'>
                                                这种类型的锅 当时我是在唯品会买的这套锅，三个锅四百多点，也不贵。 主要是冲它颜值去的，真的觉得很好看。 它的涂层容易坏这个都是小问题。 主要让人很塞心的是它的一个设计问题，不知道你们有发现没...
                                                </div>
                                                <div className='info'>
                                                    <img src="/images/mock-circle-head.png" className="head"></img>
                                                    <div className='name color-2'>值得君（圈主）</div>
                                                    <img src="/images/like.png"></img>
                                                    <div className='color-1'>10</div>
                                                </div>
                                            </div>
                                            <div>
                                                <img src="/images/mock-circle-content.png" className='circle-img'></img>
                                            </div>
                                        </div>
                                        <div className='post' onClick={()=>{
                                            navigate('/circle/'+2)
                                        }}>
                                            <div className='fx-box fx-fd-c fx-1'>
                                                <div className='color-1'>
                                                这种类型的锅 当时我是在唯品会买的这套锅，三个锅四百多点，也不贵。 主要是冲它颜值去的，真的觉得很好看。 它的涂层容易坏这个都是小问题。 主要让人很塞心的是它的一个设计问题，不知道你们有发现没...
                                                </div>
                                                <div className='info'>
                                                    <img src="/images/mock-circle-head.png" className="head"></img>
                                                    <div className='name color-2'>值得君（圈主）</div>
                                                    <img src="/images/like.png"></img>
                                                    <div className='color-1'>10</div>
                                                </div>
                                            </div>
                                            <div>
                                                <img src="/images/mock-circle-content.png" className='circle-img'></img>
                                            </div>
                                        </div> */}
                                        {
                                            feeds.map((feed,index)=>{
                                                return (
                                                    <Link to={"/moment/"+feed.id} key={"fk_"+index} ><div className='post'>
                                                        <div className='fx-box fx-fd-c fx-1'>
                                                            <div className='color-1'>
                                                            {feed?.desc.split('\\n').map((f,idx)=>{
                                                                return (
                                                                    <p key={'pk_'+idx} className='desc-line'>{f}</p>
                                                                )
                                                            })}
                                                            </div>
                                                            <div className='info'>
                                                                <img src={feed?.avatar??'/images/avatar.png'} className="head"></img>
                                                                <div className='name color-2'>{feed.nickname}</div>
                                                                <img src="/images/like.png" className='stat'></img>
                                                                <div className='color-1 stat2'>{feed.like_count}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {
                                                                feed.video?
                                                                <video controls poster={feed?.video?.replace('http:','https:')} className='circle-img'>
                                                                    <source  src={feed?.video?.replace('http://','https://')}
                                                                        type="video/mp4"/>
                                                                </video>    
                                                                :
                                                                <img src={feed?.photo?.length?feed?.photo[0]:"/images/mock-circle-content.png"} className='circle-img'></img>
                                                            }
                                                            
                                                        </div>
                                                    </div> 
                                                    </Link>
                                                )
                                            })
                                        }
                                        <div className='post-bottom' onClick={()=>{
                                            setLoading(true)
                                            console.log('[DEBUG]next_page:',nextPage);
                                            service.loadFeeds(nextPage).then(ret=>{
                                                console.log(ret.data.data);
                                                setFeeds([...feeds,...ret.data.data.data]);
                                                setNextPage(ret.data.data.next_page);
                                            }).finally(()=>{
                                                setTimeout(()=>{
                                                    setLoading(false);
                                                },500)
                                            });
                                        }}>
                                            <div className='btn'>
                                                {loading?
                                                <Loading/>:<span>阅读更多</span>
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="right-col">
                                    <Suggestion/>
                                </div>
                            </div>
                            
                        </div>
        </div>
        
    )
}