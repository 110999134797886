import { useEffect, useState } from "react";
// import { Checkbox, Toast } from "react-vant";
import "./Header.css"
import {Menu} from './menu';
import {Sign} from './sign';
import * as service from '../../service';
import {connect} from 'react-redux';
import { useNavigate, useNavigation } from "react-router-dom";
import LoginDialog from "./login_dialog";
// import CreateDialog from './create_dialog';

import CreateCircle from '../CreateCircle';
import KYC from "../KYC";
import Report from "../Report";
import ReportSelect from '../Report/select';
import Unlock from "../Unlock";
import Reward from "../Reward";
import { SearchInput } from "./search";
import Popup from 'reactjs-popup';
import { Toast } from "react-vant";
const H5Menu = connect(state=>{return {profile:state.profile}},dispatch=>({dispatch}))((props)=>{
    const navigate=useNavigate();
    return (<Popup
        trigger={open=>{
            return (
                <div className="h5-menu">
                    <img src="/images/h5-menu.png"/>
                </div>
            )
        }}
        position="bottom right"
        closeOnDocumentClick
    >
        <div className="dropdown-menu">
        <div className="h5-item" onClick={()=>{
                        navigate('/');
        }}>
            <img src="/images/home-active.png"></img><span>推荐</span>
        </div>
        <div className="h5-item" onClick={()=>{
                        navigate('/moments');
        }}>
            <img src="/images/circle-active.png"></img><span>圈子</span></div>
        <div className="h5-item" onClick={()=>{
                        navigate('/feedback');
        }}><img src="/images/feedback-active.png"></img><span>反馈</span></div>
        
        {props.profile?
            <div className="h5-item" onClick={()=>{
                        navigate('/updateinfo');
            }}><img src="/images/dropdown.png"></img><span>设置</span></div>:
            <div className="h5-item" onClick={()=>{
                props.dispatch({type:'SHOW_LOGIN_DIALOG'})
            }}><img src="/images/dropdown.png"></img><span>登录</span></div>
        }
        </div>
    
    </Popup>)
})


const Dropdown =(props)=>{
    const navigate = useNavigate();
    return (
        <Popup trigger={open => (    
            <img className="dropdownimg" src="/images/dropdown.png"></img>
                )}
            position="bottom center"
            closeOnDocumentClick >
                <div className="dropdown-menu">
                    <div className="item" onClick={()=>{
                        navigate('/updateinfo');
                    }}>我的资料</div>
                    <div className="item disabled">我的发布</div>
                    <div className="item" onClick={()=>{
                        navigate('/wallet')
                    }}>我的钱包</div>
                    <div className="item" onClick={()=>{
                        navigate('/accounthistory')
                    }}>收支明细</div>
                    <div className="item" onClick = {()=>{props.onLogout();}}>退出登录</div>
                </div>
         
        </Popup>
    )
} 

const HeaderRaw=(props)=>{
    const [showRegister,setShowRegister]  = useState(false);
    const [showLogin,setShowLogin]  = useState(false);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [password2,setPassword2] = useState('');
    const [code,setCode] = useState('');
    const [showCreate,setShowCreate] = useState();
    const [showKYC,setShowKYC] = useState();
    const [showPost,setShowPost]  = useState();
    const [circleParams,setCircleParams] = useState(null);
    
    const navigate = useNavigate();

    useEffect(()=>{
        setShowLogin(props.showLoginDialog);
    },[setShowLogin,props.showLoginDialog]);
    // console.log(props?.loginInfo);
    return(
        <div className="fx-box debug header fx-ai-c">
            <div className="fx-box fx-1 fx-ai-c ">
                <div className="logo-container">
                    <img src='/images/header-logo.png' className="logo"/>
                </div>
                <div className="h5-logo-container">
                    <img src='/images/uglogo.png' className="h5-logo"/>
                </div>
                <Menu></Menu>
                <SearchInput/>
            </div>
            
            {
                props.loginInfo?
                <div className="header-sign fx-box fx-ai-c fx-05">
                    {/* <div className="btn" style={{paddingRight:12}} onClick={()=>{
                        navigate('/my');
                    }}>
                       {props?.profile?.nickname}
                    </div> */}
                    {/* <div className="register btn" onClick={()=>{
                        props.dispatch({type:'LOGOUT'})
                    }}>
                        退出登录
                    </div> */}
                    <div className="fx-box fx-ai-c" style={{marginRight:15,cursor:'pointer'}}>
                        <div className="create-link" onClick={()=>{
                            setShowCreate(true);
                        }}>创建圈子</div>

                        <img src="/images/create.png" className="dropdownimg" onClick = {()=>{
                            // setShowPost(true);
                            
                            if(!props.profile?.circle||props.profile?.circle?.status=='pending'){
                                Toast.info('圈子创建审核完成之后才能发布内容喔！');
                            }
                        }}/>
                    </div>
                    <Dropdown onLogout={()=>{
                        props.dispatch({type:'LOGOUT'})
                    }}/>
                </div>
                :
                <Sign
                onLogin={()=>{
                    // setShowLogin(true);
                    props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                }}
                onCreate={()=>{
                    // setShowCreate(true);
                    props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                }}></Sign>
            }
            <H5Menu/>
            
            <LoginDialog visible={props.showLoginDialog}/>
            {/* <PostFeedDialog /> */}
            <CreateCircle 
            
            onCreatePayCircle={(options)=>{
                setCircleParams(options);
                setShowCreate(false);
                setShowKYC(true);
            }}

            onClose = {()=>{
                setShowCreate(false);
            }}
            visible={showCreate}/>
            <KYC
            params = {circleParams}
            onClose = {()=>{
                setShowKYC(false);
            }}
            visible={showKYC}/>
            {/* <Reward onClose = {()=>{
                props.dispatch({type:'CLOSE_CREATE_DIALOG'});
            }}
            visible={props.showCreateDialog}/> */}

        </div>
    )
}

export const Header=connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        profile:state.profile,
        showLoginDialog:state.showLoginDialog,
        showCreateDialog:state.showCreateDialog
    }
},(dispatch)=>({dispatch}))(HeaderRaw);