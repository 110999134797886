import { Outlet } from "react-router-dom"
import {Header} from "../../components/Header";
import {Footer} from "../../components/Footer";

export const Layout = (props)=>{
    return(
        <div className="fx-box fx-fd-c fx-ai-c page-container" style={{backgroundColor:'#EDEDED'}}>
            <Header></Header>
            <div style={{maxWidth:1444,width:'100%'}}>
                <Outlet/>
            </div>
            <Footer></Footer>
        </div>
        
    )
}