import { useState } from "react";
import { connect } from "react-redux";
import { Popup, Toast } from "react-vant";
import * as service from '../../service';


const LoginDialog = (props)=>{
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    return (
        <Popup 
            className="login-dialog"
            visible={props.visible}
            onClose = {()=>{
                props.dispatch({type:'CLOSE_LOGIN_DIALOG'})
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.dispatch({type:'CLOSE_LOGIN_DIALOG'})
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className='h-s-active' style={{marginRight:26}}>
                            注册登录</div>
                    </div>
                            <div className="fx-box register-form-row">
                                <div className="phone-area">
                                    +86
                                </div>
                                <input className="register-input fx-1" 
                                placeholder="请输入手机号"
                                value={username} onChange={(e)=>{
                                    setUsername(e.target.value);
                                }}/>
                            </div>
                            <div className="fx-box  register-form-row">
                                <input type='text' 
                                placeholder="请输入验证码"
                                className="register-input fx-1" value={password} onChange={(e)=>{
                                    setPassword(e.target.value);
                                }}/>
                                <div className="code-btn" onClick={()=>{
                                    service.getVerifyCode(
                                        {
                                            phone:       username,
                                            'country_code': '+86',
                                            digit: 6
                                        }
                                    ).then((ret)=>{
                                        Toast.success('短信发送成功！');
                                    })
                                }}>
                                    获取验证码
                                </div>
                            </div>
                            
                            <div className="fx-box fx-ai-c fx-jc-c login-btn" style={{marginTop:'auto'}}> 
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" onClick={()=>{
                                    service.login({username,code:password}).then(ret=>{
                                        console.log('[DEBUG]login return:',ret);
                                        props.dispatch({
                                            type:'LOGIN_OK',
                                            payload:Object.assign({},ret.data.data,{username})
                                        })
                                    }).then(()=>{
                                        props.dispatch({type:'CLOSE_LOGIN_DIALOG'})
                                    }).catch(err=>{
                                        console.error(err);
                                    });
                                }}>
                                    登录
                                </div>
                            </div>
                        
                    
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(LoginDialog);